@font-face {
  font-family: "iconFont";
  src: url("../fonts/iconFont.woff2") format("woff2"), url("../fonts/iconFont.woff") format("woff"), url("../fonts/iconFont.ttf") format("truetype"), url("../fonts/iconFont.svg#iconFont") format("svg");
}

.icon-youtube:before, .icon-update:before, .icon-suitable:before, .icon-search:before, .icon-quote-top:before, .icon-quote-bottom:before, .icon-play:before, .icon-logout:before, .icon-lock:before, .icon-linkdin:before, .icon-line-curve:before, .icon-levels:before, .icon-levels-fill:before, .icon-instagram:before, .icon-home:before, .icon-home-fill:before, .icon-eye:before, .icon-eye-line:before, .icon-expand:before, .icon-edit:before, .icon-earth:before, .icon-corner-down:before, .icon-copy:before, .icon-close:before, .icon-checked:before, .icon-check:before, .icon-check-1:before, .icon-calendar:before, .icon-arrow-right:before, .icon-add:before {
  font-family: "iconFont", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}

.icon-add:before {
  content: "\e001";
}

.icon-arrow-right:before {
  content: "\e002";
}

.icon-calendar:before {
  content: "\e003";
}

.icon-check-1:before {
  content: "\e004";
}

.icon-check:before {
  content: "\e005";
}

.icon-checked:before {
  content: "\e006";
}

.icon-close:before {
  content: "\e007";
}

.icon-copy:before {
  content: "\e008";
}

.icon-corner-down:before {
  content: "\e009";
}

.icon-earth:before {
  content: "\e00a";
}

.icon-edit:before {
  content: "\e00b";
}

.icon-expand:before {
  content: "\e00c";
}

.icon-eye-line:before {
  content: "\e00d";
}

.icon-eye:before {
  content: "\e00e";
}

.icon-home-fill:before {
  content: "\e00f";
}

.icon-home:before {
  content: "\e010";
}

.icon-instagram:before {
  content: "\e011";
}

.icon-levels-fill:before {
  content: "\e012";
}

.icon-levels:before {
  content: "\e013";
}

.icon-line-curve:before {
  content: "\e014";
}

.icon-linkdin:before {
  content: "\e015";
}

.icon-lock:before {
  content: "\e016";
}

.icon-logout:before {
  content: "\e017";
}

.icon-play:before {
  content: "\e018";
}

.icon-quote-bottom:before {
  content: "\e019";
}

.icon-quote-top:before {
  content: "\e01a";
}

.icon-search:before {
  content: "\e01b";
}

.icon-suitable:before {
  content: "\e01c";
}

.icon-update:before {
  content: "\e01d";
}

.icon-youtube:before {
  content: "\e01e";
}

@font-face {
  font-family: "EuclidCircularA";
  src: url("../fonts/EuclidCircularA-Bold.woff2") format("woff2"), url("../fonts/EuclidCircularA-Bold.woff") format("woff"), url("../fonts/EuclidCircularA-Bold.ttf") format("truetype"), url("../fonts/EuclidCircularA-Bold.svg#EuclidCircularA") format("svg");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "EuclidCircularA";
  src: url("../fonts/EuclidCircularA-Light.woff2") format("woff2"), url("../fonts/EuclidCircularA-Light.woff") format("woff"), url("../fonts/EuclidCircularA-Light.ttf") format("truetype"), url("../fonts/EuclidCircularA-Light.svg#EuclidCircularA") format("svg");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "EuclidCircularA";
  src: url("../fonts/EuclidCircularA-Medium.woff2") format("woff2"), url("../fonts/EuclidCircularA-Medium.woff") format("woff"), url("../fonts/EuclidCircularA-Medium.ttf") format("truetype"), url("../fonts/EuclidCircularA-Medium.svg#EuclidCircularA") format("svg");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "EuclidCircularA";
  src: url("../fonts/EuclidCircularA-Regular.woff2") format("woff2"), url("../fonts/EuclidCircularA-Regular.woff") format("woff"), url("../fonts/EuclidCircularA-Regular.ttf") format("truetype"), url("../fonts/EuclidCircularA-Regular.svg#EuclidCircularA") format("svg");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "EuclidCircularA";
  src: url("../fonts/EuclidCircularA-SemiBold.woff2") format("woff2"), url("../fonts/EuclidCircularA-SemiBold.woff") format("woff"), url("../fonts/EuclidCircularA-SemiBold.ttf") format("truetype"), url("../fonts/EuclidCircularA-SemiBold.svg#EuclidCircularA") format("svg");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Black.eot");
  src: url("../fonts/Gilroy-Black.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Black.woff") format("woff"), url("../fonts/Gilroy-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Extrabold.eot");
  src: url("../fonts/Gilroy-Extrabold.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Extrabold.woff") format("woff"), url("../fonts/Gilroy-Extrabold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Bold.eot");
  src: url("../fonts/Gilroy-Bold.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Bold.woff") format("woff"), url("../fonts/Gilroy-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Semibold.eot");
  src: url("../fonts/Gilroy-Semibold.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Semibold.woff") format("woff"), url("../fonts/Gilroy-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Medium.eot");
  src: url("../fonts/Gilroy-Medium.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Medium.woff") format("woff"), url("../fonts/Gilroy-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Regular.eot");
  src: url("../fonts/Gilroy-Regular.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Regular.woff") format("woff"), url("../fonts/Gilroy-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Light.eot");
  src: url("../fonts/Gilroy-Light.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Light.woff") format("woff"), url("../fonts/Gilroy-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Thin.eot");
  src: url("../fonts/Gilroy-Thin.eot?iefix") format("embedded-opentype"), url("../fonts/Gilroy-Thin.woff") format("woff"), url("../fonts/Gilroy-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}

html,
body {
  width: 100%;
  min-height: 100vh;
  background: #F0F3F5;
}

body {
  /* overflow-x: hidden;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; */
  /* -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; */
  margin: 0;
  padding: 0;
  background-color: #f0f3f5;
  line-height: 1.42857143;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.04em;
  font-family: "EuclidCircularA", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
*:before, *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button[disabled] {
  opacity: 0.55;
  cursor: not-allowed;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
}

p,
h1, h2, h3, h4, h5, h6,
hr {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

a {
  outline: none;
  text-decoration: none;
}
a:hover, a:focus {
  text-decoration: none;
}

input[type=text] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

picture {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.c-form__field {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100%;
  margin-bottom: 12px;
}
.c-form__field.c-form__field--icon > input,
.c-form__field.c-form__field--icon > select,
.c-form__field.c-form__field--icon > textarea {
  padding-right: 40px;
}
.c-form__field.c-form__field--icon .icon-font {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 76px;
  margin: 0;
  padding: 25px 12px 0;
  font-size: 18px;
  color: #000000;
}
.c-form__field-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .c-form__field-group {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.c-form__field-group.c-form__field-group--8020 > div:nth-of-type(1) {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  min-width: 1px;
  max-width: 100%;
  padding-right: 20px;
}
@media screen and (max-width: 767px) {
  .c-form__field-group.c-form__field-group--8020 > div:nth-of-type(1) {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
    padding-right: 0;
  }
}
.c-form__field-group.c-form__field-group--8020 > div:nth-of-type(2) {
  width: 100%;
  max-width: 145px;
}
@media screen and (max-width: 767px) {
  .c-form__field-group.c-form__field-group--8020 > div:nth-of-type(2) {
    max-width: 100%;
  }
}
.c-form__field-group > div {
  width: 100%;
  max-width: calc((100% - 20px) / 2);
}
@media screen and (max-width: 767px) {
  .c-form__field-group > div {
    max-width: 100%;
  }
}
.c-form__field.is-error label {
  color: #F1526F;
}
.c-form__field.is-error input {
  border-color: #F1526F;
  color: #F1526F;
}
.c-form__field > label,
.c-form__field > small {
  z-index: 2;
  position: absolute;
  top: 12px;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  color: #000000;
  font-size: 14px;
}
.c-form__field > input,
.c-form__field > select,
.c-form__field > textarea {
  outline: none;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 76px;
  margin: 0;
  padding: 25px 12px 0;
  border: 1px solid #FBFBFB;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 12px;
  background-color: #FBFBFB;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.05em;
}
.c-form__field > input.placeholder,
.c-form__field > select.placeholder,
.c-form__field > textarea.placeholder {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.05em;
  color: #A1A1A1;
}
.c-form__field > input:-moz-placeholder,
.c-form__field > select:-moz-placeholder,
.c-form__field > textarea:-moz-placeholder {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.05em;
  color: #A1A1A1;
}
.c-form__field > input::-moz-placeholder,
.c-form__field > select::-moz-placeholder,
.c-form__field > textarea::-moz-placeholder {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.05em;
  color: #A1A1A1;
}
.c-form__field > input:-ms-input-placeholder,
.c-form__field > select:-ms-input-placeholder,
.c-form__field > textarea:-ms-input-placeholder {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.05em;
  color: #A1A1A1;
}
.c-form__field > input::-webkit-input-placeholder,
.c-form__field > select::-webkit-input-placeholder,
.c-form__field > textarea::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.05em;
  color: #A1A1A1;
}
.c-form__field > input + p,
.c-form__field > select + p,
.c-form__field > textarea + p {
  padding-top: 5px;
  padding-left: 12px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(140, 140, 140, 0.8);
}
.c-form__field > input + a,
.c-form__field > select + a,
.c-form__field > textarea + a {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 18px;
  height: 18px;
}
.c-form__field > input + a .icon-font,
.c-form__field > select + a .icon-font,
.c-form__field > textarea + a .icon-font {
  font-size: 16px;
  color: #000000;
}
.c-form__field > input + a .icon-font:nth-of-type(1),
.c-form__field > select + a .icon-font:nth-of-type(1),
.c-form__field > textarea + a .icon-font:nth-of-type(1) {
  opacity: 0;
}
.c-form__field > input + a .icon-font:nth-of-type(2),
.c-form__field > select + a .icon-font:nth-of-type(2),
.c-form__field > textarea + a .icon-font:nth-of-type(2) {
  position: absolute;
}
.c-form__field select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #FBFBFB url(../img/corner-down.svg) no-repeat calc(100% - 12px) calc(100% - 20px);
}
.c-form__field textarea {
  resize: none;
  height: 106px;
  padding-top: 30px;
}
.c-form__divider {
  width: 100%;
  height: 1px;
  margin-top: 8px;
  margin-bottom: 20px;
  border-radius: 12px;
  background-color: #e1e1e1;
}

.icon-font {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
}

.logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.logo__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.logo__img {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.c-btn {
  outline: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: auto;
  height: 38px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #000;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 8px;
  background-color: #000000;
  font-size: 16px;
  font-weight: 500;
  color: #FDFDFD;
  -webkit-transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}
@media screen and (max-width: 767px) {
  .c-btn {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
  }
}
.c-btn:hover {
  background-color: #3C3C3C;
  -webkit-transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}
.c-btn:active {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #969696;
  background-color: #969696;
  color: rgba(255, 255, 255, 0.3);
  -webkit-transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}
.c-btn--big {
  height: 44px;
}
.c-btn--extrabig {
  height: 52px;
}
.c-btn--shadow {
  -webkit-box-shadow: 0px 50px 40px rgba(0, 0, 0, 0.07), 0px 20.8888px 16.711px rgba(0, 0, 0, 0.0503198), 0px 11.1682px 8.93452px rgba(0, 0, 0, 0.0417275), 0px 6.26078px 5.00862px rgba(0, 0, 0, 0.035), 0px 3.32505px 2.66004px rgba(0, 0, 0, 0.0282725), 0px 1.38363px 1.1069px rgba(0, 0, 0, 0.0196802);
          box-shadow: 0px 50px 40px rgba(0, 0, 0, 0.07), 0px 20.8888px 16.711px rgba(0, 0, 0, 0.0503198), 0px 11.1682px 8.93452px rgba(0, 0, 0, 0.0417275), 0px 6.26078px 5.00862px rgba(0, 0, 0, 0.035), 0px 3.32505px 2.66004px rgba(0, 0, 0, 0.0282725), 0px 1.38363px 1.1069px rgba(0, 0, 0, 0.0196802);
}
.c-btn__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.c-btn__wrapper--center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.c-btn__wrapper--between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.c-btn .icon-font + span {
  padding-left: 10px;
}

/* ======= */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ======= */